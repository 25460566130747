<template>
    <MenuLink @click="click" href="#how-it-works-block">How it works </MenuLink>
    <MenuLink @click="click" href="#participating-locations-block"
        >Locations</MenuLink
    >
    <MenuLink @click="click" href="#faq-block"> FAQ </MenuLink>
</template>
<script setup>
import MenuLink from '@/Components/menu/MenuLink.vue'
const emits = defineEmits(['click'])
function click() {
    emits('click')
}
</script>
