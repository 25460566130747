<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="11" y="1" width="13" height="2" fill="#F6F6F6" />
        <rect y="11" width="24" height="2" fill="#F6F6F6" />
        <rect y="21" width="24" height="2" fill="#F6F6F6" />
    </svg>
</template>
