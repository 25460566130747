<template>
  <div id="details" class="mb-8">
    <!--TODO add validation-->
    <div class="email-input flex flex-col max-w-80 mb-4">
      <label for="email" class="text-lg mb-2">Email address:</label>
      <input type="email" id="email" name="email" class="border-plu-blue border-2 rounded" />
    </div>
    <div class="info-text">
      <h3 class="font-bold mb-1.5 text-md">Why do we ask for your email?</h3>
      <p class="text-sm">{{ emailInfoText }}</p>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  processType: {
    type: String,
    required: true,
  },
});

const emailInfoText = computed(() => {
  return props.processType === 'rent'
    ? 'The email you enter will be tied to your Plu umbrella rental. You will need it when you return your umbrella at a machine later.'
    : 'Please enter the same email you used when borrowing your Plu umbrella. Otherwise we cannot refund your deposit correctly.';
});
</script>
