<template>
  <section id="rent" class="bg-white text-black">
    <!-- Render MachineInfo only when on RentInitialPage -->
    <MachineInfo v-if="isInitialPage"></MachineInfo>
    <RentTitle :stepTitle="currentStepTitle"></RentTitle>
    <inner-constraint class="h-full pt-8 pb-12 flex flex-col">
      <component :is="currentStep.component" @startProcess="startProcess" :processType="currentStep.selectionType" />
      <RentButton v-if="showRentButton" :buttonText="currentButtonText" @nextStep="nextStep" />
    </inner-constraint>
  </section>
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import InnerConstraint from "@/Components/InnerConstraint.vue";
import RentTitle from "@/Components/rent/RentTitle.vue";
import RentInitialPage from "@/Components/rent/RentInitialPage.vue";
import RentDetails from "@/Components/rent/RentDetails.vue";
import RentInformation from "@/Components/rent/pick-up/RentInformation.vue";
import RentPayment from "@/Components/rent/pick-up/RentPayment.vue";
import RentComplete from "@/Components/rent/RentComplete.vue";
import RentButton from "@/Components/rent/RentButton.vue";
import MachineInfo from "@/Components/rent/MachineInfo.vue";
import { router, usePage } from '@inertiajs/vue3'
// Track whether the current step is RentInitialPage
const isInitialPage = ref(true);

const selectionType = ref(null)
const currentStep = ref({
  component: RentInitialPage,
  stepTitle: 'What would you like to do?',
  buttonText: null,
});

const currentStepTitle = computed(() => currentStep.value.stepTitle);
const currentButtonText = computed(() => currentStep.value.buttonText);
const showRentButton = computed(() => currentStep.value.buttonText !== null);

// Following steps
const allSteps = {
  init: {
    component: RentInitialPage,
    stepTitle: 'What would you like to do?',
    buttonText: null,
},
  // PICK-UP UMBRELLA
  rent: {
    email: {
      component: RentDetails,
      stepTitle: 'Your details',
      buttonText: 'Continue',
    },
    info:{
      component: RentInformation,
      stepTitle: 'How it works',
      buttonText: 'Rent your umbrella',
    },
    payment:{
      component: RentPayment,
      stepTitle: 'Payment',
      buttonText: 'Continue to Payment',
    },
    complete: {
      component: RentComplete,
      stepTitle: 'Thank you!',
      buttonText: null, // No button in the last step
    },
  },
  // RETURN UMBRELLA
  return: {
    details:
    {
      component: RentDetails,
      stepTitle: 'Your details',
      buttonText: 'Continue',
    },
    complete:
    {
      component: RentComplete,
      stepTitle: 'Thank you for using Plu!',
      buttonText: null, // No button in the last step
    },
  },
};


// Starts either rent or return process based on the user's selection
function startProcess(selection) {
  isInitialPage.value = false; // Turn off the initial page flag when starting a process
  selectionType.value = selection
  router.visit(`/${selection}/${Object.keys(allSteps[selection])[0]}`)
}

function nextStep() {
  const currentIndex = Object.keys(allSteps[selectionType.value]).indexOf(props.step)
  router.visit(`/${selectionType.value}/${Object.keys(allSteps[selectionType.value])[currentIndex + 1]}`)
}
const props = defineProps({ step: String })

watchEffect(() => {
 selectionType.value = usePage().url.split('/')[1]
 currentStep.value = selectionType && props.step ? allSteps[selectionType.value][props.step] : allSteps['init']
})
</script>
