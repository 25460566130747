<template>
  <div id="complete" class="text-center p-8 mb-8">
    <div class="icon flex justify-center mb-12">
      <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="1" width="98" height="98" rx="49" stroke="#66B3BD" stroke-width="2"/>
        <mask id="path-2-outside-1_111_6110" maskUnits="userSpaceOnUse" x="27" y="32.5" width="47" height="35" fill="black">
          <rect fill="white" x="27" y="32.5" width="47" height="35"/>
          <path d="M71.0625 35.4375C72.2812 36.5625 72.2812 38.5312 71.0625 39.6562L47.0625 63.6562C45.9375 64.875 43.9688 64.875 42.8438 63.6562L30.8438 51.6562C29.625 50.5312 29.625 48.5625 30.8438 47.4375C31.9688 46.2188 33.9375 46.2188 35.0625 47.4375L44.9062 57.2812L66.8438 35.4375C67.9688 34.2188 69.9375 34.2188 71.0625 35.4375Z"/>
        </mask>
        <path d="M71.0625 35.4375L69.5929 36.7941L69.6472 36.8528L69.7059 36.9071L71.0625 35.4375ZM71.0625 39.6562L69.7059 38.1866L69.6766 38.2138L69.6483 38.242L71.0625 39.6562ZM47.0625 63.6562L45.6483 62.242L45.62 62.2703L45.5929 62.2997L47.0625 63.6562ZM42.8438 63.6562L44.3134 62.2997L44.2862 62.2703L44.258 62.242L42.8438 63.6562ZM30.8438 51.6562L32.258 50.242L32.2297 50.2138L32.2003 50.1866L30.8438 51.6562ZM30.8438 47.4375L32.2003 48.9071L32.2591 48.8528L32.3134 48.7941L30.8438 47.4375ZM35.0625 47.4375L33.5929 48.7941L33.62 48.8234L33.6483 48.8517L35.0625 47.4375ZM44.9062 57.2812L43.492 58.6955L44.9032 60.1067L46.3174 58.6985L44.9062 57.2812ZM66.8438 35.4375L68.2549 36.8547L68.2848 36.825L68.3134 36.7941L66.8438 35.4375ZM69.7059 36.9071C70.0668 37.2402 70.0668 37.8536 69.7059 38.1866L72.4191 41.1259C74.4957 39.2089 74.4957 35.8848 72.4191 33.9679L69.7059 36.9071ZM69.6483 38.242L45.6483 62.242L48.4767 65.0705L72.4767 41.0705L69.6483 38.242ZM45.5929 62.2997C45.2598 62.6605 44.6464 62.6605 44.3134 62.2997L41.3741 65.0128C43.2911 67.0895 46.6152 67.0895 48.5321 65.0128L45.5929 62.2997ZM44.258 62.242L32.258 50.242L29.4295 53.0705L41.4295 65.0705L44.258 62.242ZM32.2003 50.1866C31.8395 49.8536 31.8395 49.2402 32.2003 48.9071L29.4872 45.9679C27.4105 47.8848 27.4105 51.2089 29.4872 53.1259L32.2003 50.1866ZM32.3134 48.7941C32.6464 48.4332 33.2598 48.4332 33.5929 48.7941L36.5321 46.0809C34.6152 44.0043 31.2911 44.0043 29.3741 46.0809L32.3134 48.7941ZM33.6483 48.8517L43.492 58.6955L46.3205 55.867L36.4767 46.0233L33.6483 48.8517ZM46.3174 58.6985L68.2549 36.8547L65.4326 34.0203L43.4951 55.864L46.3174 58.6985ZM68.3134 36.7941C68.6464 36.4332 69.2598 36.4332 69.5929 36.7941L72.5321 34.0809C70.6152 32.0043 67.2911 32.0043 65.3741 34.0809L68.3134 36.7941Z" fill="#66B3BD" mask="url(#path-2-outside-1_111_6110)"/>
      </svg>
    </div>
    <h3 class="text-2xl font-bold mb-2">
      {{ isRent ? 'Payment complete!' : 'Deposit refunded!' }}
    </h3>
    <p class="text-lg">
      {{ isRent
      ? 'Please take your umbrella out of the machine. Don’t forget to return it after you are done!'
      : 'Thank you for borrowing a Plu umbrella. See you on your next rainy day!'
      }}
    </p>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  processType: String
});

const isRent = computed(() => props.processType === 'rent');
</script>
