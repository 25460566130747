<template>
    <transition name="bottom">
        <div
            v-if="open"
            class="fixed left-0 top-0 z-50 h-screen max-h-screen w-screen bg-plu-black p-6 text-white"
        >
            <div class="flex justify-end">
                <CloseIcon
                    role="button"
                    @click="close"
                    class="h-6 w-6"
                ></CloseIcon>
            </div>
            <slot></slot>
        </div>
    </transition>
</template>
<script setup>
import CloseIcon from '@/Components/images/icons/CloseIcon.vue'

defineProps({
    open: Boolean,
})
const emit = defineEmits(['close'])

function close() {
    emit('close')
}
</script>
