<template>
    <FullScreenOverlay
        class="flex flex-col md:hidden"
        :open="open"
        @close="close"
    >
        <div class="mt-16 flex flex-grow flex-col gap-y-6 text-2xl">
            <MenuLinkItems @click="close"></MenuLinkItems>
        </div>
        <div class="text-center">
            <primary-button
                href="#keep-in-touch-block"
                @click="close"
                v-animate-in
                >Keep in touch</primary-button
            >
        </div>
    </FullScreenOverlay>
</template>
<script setup>
import FullScreenOverlay from '@/Components/overlays/FullScreenOverlay.vue'
import MenuLinkItems from '@/Components/menu/MenuLinkItems.vue'
import PrimaryButton from '@/Components/buttons/PrimaryButton.vue'

const emit = defineEmits(['close'])

defineProps({
    open: Boolean,
})

function close() {
    emit('close')
}
</script>
