<template>
    <div class="w-full bg-plu-black text-white">
        <InnerConstraint>
            <div
                class="hidden w-full flex-wrap items-center justify-between py-8 md:flex"
            >
                <logos v-animate-in class="shrink-0"></logos>
                <div v-animate-in class="flex gap-x-4 text-xl">
                    <MenuLinkItems></MenuLinkItems>
                </div>
                <PrimaryButton v-animate-in href="#keep-in-touch-block"
                    >Keep in touch</PrimaryButton
                >
            </div>
            <div
                class="flex w-full items-center justify-between py-6 md:hidden"
            >
                <logos class=""></logos>
                <MenuButton class="shrink-0" @click="openMenu"></MenuButton>
            </div>
        </InnerConstraint>
    </div>
    <Teleport v-if="mounted" to="body">
        <MobileMenuOverlay
            :open="menuOpen"
            @close="closeMenu"
        ></MobileMenuOverlay>
    </Teleport>
</template>
<script setup lang="ts">
import InnerConstraint from '@/Components/InnerConstraint.vue'
import { onMounted, ref } from 'vue'
import Logos from '@/Components/logos/Logos.vue'
import MenuLinkItems from '@/Components/menu/MenuLinkItems.vue'
import PrimaryButton from '@/Components/buttons/PrimaryButton.vue'
import MenuButton from '@/Components/menu/MenuButton.vue'
import MobileMenuOverlay from '@/Components/menu/MobileMenuOverlay.vue'

const mounted = ref(false)
const menuOpen = ref(false)

function openMenu() {
    menuOpen.value = true
}

function closeMenu() {
    menuOpen.value = false
}

onMounted(() => {
    mounted.value = true
})
</script>
