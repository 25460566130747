<template>
  <div id="machine-info" class="bg-plu-black text-white">
    <div class="container mx-auto max-w-screen-xl px-4 pt-4 pb-12">
      <h2 class="text-3xl font-medium font-BwGRadual">Welcome to Plu</h2>
      <!--TODO add machine number-->
      <h2 class="text-3xl text-plu-blue font-medium font-BwGRadual mb-2">Machine <span>#NUMBER</span></h2>
      <div class="flex items-center mb-10">
        <i >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333374 6.37845C0.333374 3.31185 2.89596 0.833252 5.99564 0.833252C9.10411 0.833252 11.6667 3.31185 11.6667 6.37845C11.6667 7.92372 11.1047 9.35832 10.1797 10.5743C9.15924 11.9156 7.90151 13.0843 6.48571 14.0015C6.16171 14.2135 5.86931 14.2295 5.51364 14.0015C4.08987 13.0843 2.8321 11.9156 1.82037 10.5743C0.894694 9.35832 0.333374 7.92372 0.333374 6.37845ZM4.12953 6.55112C4.12953 7.57839 4.96784 8.38639 5.99564 8.38639C7.02417 8.38639 7.87057 7.57839 7.87057 6.55112C7.87057 5.53179 7.02417 4.68447 5.99564 4.68447C4.96784 4.68447 4.12953 5.53179 4.12953 6.55112Z" fill="white"/>
          </svg>
        </i>
        <!--TODO add location-->
        <p class="text-sm ml-2">Location Test 1</p>
      </div>
      <div class="flex items-center">
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <path d="M21 0C26.5695 0 31.911 2.21214 35.8492 6.14977C39.7875 10.0874 42 15.428 42 20.9966C41.9999 21.568 41.7901 22.1196 41.4103 22.5466C41.0306 22.9736 40.5073 23.2464 39.9397 23.3133L39.6667 23.3296H23.3333V34.9944C23.334 35.589 23.5617 36.1609 23.97 36.5933C24.3782 37.0257 24.9362 37.2859 25.5299 37.3207C26.1236 37.3556 26.7082 37.1625 27.1643 36.7808C27.6203 36.3992 27.9134 35.8578 27.9837 35.2673L28 34.9944C28 34.3756 28.2458 33.7822 28.6834 33.3447C29.121 32.9072 29.7145 32.6614 30.3333 32.6614C30.9522 32.6614 31.5457 32.9072 31.9832 33.3447C32.4208 33.7822 32.6667 34.3756 32.6667 34.9944C32.6684 36.8162 31.9596 38.5669 30.6909 39.8745C29.4221 41.1821 27.6934 41.9435 25.8721 41.997C24.0508 42.0504 22.2804 41.3917 20.9371 40.1608C19.5938 38.9299 18.7835 37.2238 18.6783 35.405L18.6667 34.9944V23.3296H2.33333C1.76182 23.3295 1.21021 23.1197 0.783132 22.74C0.356051 22.3603 0.0832 21.8371 0.0163333 21.2696L0 20.9966C0 15.428 2.21249 10.0874 6.15076 6.14977C10.089 2.21214 15.4305 0 21 0Z" fill="white"/>
          </svg>
        </i>
        <div class="ml-4">
          <!--TODO add umbrellas currently inside-->
          <p class="text-sm font-bold">25/30</p>
          <p class="text-sm">umbrellas currently inside</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>
