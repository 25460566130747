<template>
    <p class="text-md">
        <slot></slot>
    </p>
</template>
<style lang="scss" scoped>
:deep(span) {
    @apply font-bold;
}
</style>
