<template>
  <div class="flex justify-center gap-4">
    <div class="rent-buttons grid gap-y-4 md:grid-cols-2 md:gap-x-4 w-full">
      <RentButton
        buttonText="Rent an umbrella"
        @nextStep="startRent"
        customClasses="bg-plu-blue text-plu-black-dark"
      />
      <RentButton
        buttonText="Return an umbrella"
        @nextStep="startReturn"
        customClasses="bg-black text-white"
      />
    </div>
  </div>
</template>

<script setup>
import RentButton from "@/Components/rent/RentButton.vue";

const emit = defineEmits(['startProcess']);

const startRent = () => {
  emit('startProcess', 'rent');
};

const startReturn = () => {
  emit('startProcess', 'return');
};
</script>
